import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import FormInput from "../../components/FormInput";
import Img from "../../constants/Img";
import { connect } from "react-redux";
import * as Actions from "../../store/actions";
import { isEmpty, Alert } from "../../utils";

function RegisterPage({history, signIn}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const onClickLogin = useCallback(() => {
        if (isEmpty([email, password])) {
            Alert('Please fill all the fields: ',"error");
            return;
        }
        signIn({
            payload: {
                email: email,
                password: password,
            },
            history
        });
    }, [email, password]);


    return (
        <div id="registration" className="flex_wrapper">
            <div className="create_account">
                <div className="box1">
                    <img src={Img.regimage} alt=""/>
                    <h1>Create an account</h1>
                    <h2>
                        Just create a new account with your mail id for free, or login in
                        now, if you already have an account.
                    </h2>
                </div>
            </div>
            <div className="reg_form">
                <div className="box2">
                    <form className="form" onSubmit={(e)=>e.preventDefault()}>
                        <FormInput
                            value={email}
                            caption="Email ID"
                            onChange={setEmail}
                            id="last"
                            required
                        />
                        <FormInput
                            value={password}
                            onChange={setPassword}
                            caption="Password"
                            id="color"
                            required
                            type="password"
                        />
                    <div className="">
                        <button id="registernow" type="submit" name="button" onClick={onClickLogin}>
                            Login Now
                        </button>
                        <Link to="/auth/forgot">Forgot password</Link>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    signIn: Actions.signInUser
};

export default connect(null, mapDispatchToProps)(RegisterPage)
