import React,{useState,useEffect} from 'react'
import Switch from "react-switch";
import {connect} from 'react-redux';
import {createLeadWidget,fetchAllLeads,updateLead} from '../../store/actions';
import CustomizeWidget from '../../components/LeadCapture/CustomizeWidget'
import ReactTooltip from 'react-tooltip'

function LeadCapture({apps,createLeadWidget,fetchAllLeads,updateLead}) {
    const [enabled,setEnabled]=useState(false);
    const [didMount,setDidMount]=useState(false);
    const [isCreated,setIsCreated]=useState(false);
    const [emailApp,setEmailapp]=useState({});
    const [showCustomizeWid,setShowCustomizeWid]=useState(false);
    const handleChange=()=>{
        setEnabled(!enabled);
    }
    useEffect(()=>{
        let emailApps=apps.filter(app=>app.platform==="email");
            emailApps.sort(function(a, b){
                return a.id-b.id
            });
        if(emailApps.length){
            setIsCreated(true);
            setEmailapp(emailApps[0]);
            setEnabled(emailApps[0].status===2?true:false);
        }
    },[apps])
    useEffect(()=>{
        fetchAllLeads();
    },[])
    useEffect(() => {
        if(didMount&&!isCreated)
            createLeadWidget({
                    "name": "Wishlist Leads",
                    "desc": "Wishlist Leads",
                    "platform": "email",
                    "category": "leads",
                    "status": 2,
                    "settings": {
                      "title": "Wish to Buy Later?",
                      "button_text": "Remind",
                      "btn_text_1":"Remind",
                      "btn_text_2":"Receive",
                      "desc": "You can add this product to your wishlist",
                      "tooltip":"Not ready to buy now ? Don't worry ! We'll remind you later with an exciting offer. Just set a reminder now."
                  }
            })
        else if(didMount)
            updateLead(emailApp.id,{status:enabled?2:1});
        else
            setDidMount(true);
    }, [enabled]);

    const handleShowCustomizesWid=()=>{
        setShowCustomizeWid(!showCustomizeWid);
    }

    return (
        <div className="cart-settings uk-width-5-6@m lead-capture">
            <div className="uk-padding uk-border-rounded box-shadow-medium uk-background-default">
                <h3>Wishlist / Buy Later Widget</h3>
                <ReactTooltip/>
                <div className="uk-width-3-5@m uk-text-light uk-text-secondary uk-margin-bottom">
                Wishlist widget helps you to collect the email id and product details that a customer is interested in by simply clicking on the 'reminder' icon.
                </div>
                <div className="uk-grid  uk-child-width-1-4@m uk-child-width-1-2 uk-flex-start uk-text-left">
                    <div>
                        <div className="uk-card uk-card-default">
                            <h3 className="uk-card-title" style={{marginBottom:"15px"}}>Wish to buy Later?<span uk-icon="info" data-tip="Not ready to buy now ? Don't worry ! We'll remind you later with an exciting offer. Just set a reminder now."></span>
                            </h3>
                            <button className="uk-button uk-button-default uk-button-small">Remind Me</button>
                        </div>
                    </div>
                    {/* <div>
                        <div className="uk-card uk-card-default">
                            <h3 className="uk-card-title" style={{marginBottom:"0px"}}>Wish to buy Later?<span uk-icon="info" data-tip="Not ready to buy now ? Don't worry ! We'll remind you later with an exciting offer. Just set a reminder now."></span>
                            </h3>
                            <div className="email-input-block">
                            <div>
                                <input className="uk-input uk-width-4-5" type="text" placeholder="Eg:name@email.com" disabled/>
                            </div>
                            <div className="btn-container">
                            <button className="uk-button uk-button-default uk-button-small">Receive</button>
                            </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="uk-grid uk-grid-small uk-flex-start uk-text-left uk-margin-top ">
                    <div className="uk-width-1-5@m e-switch-container">
                        <Switch height={22} onChange={()=>{}} checked={enabled} onColor="#ef7f30" uncheckedIcon={false} checkedIcon={false} onChange={handleChange}/>
                        <span>Turned ON</span>
                    </div>
                    <div className="uk-width-4-5@m btn-right-text">
                        Want to Make Changes?<span onClick={handleShowCustomizesWid}>Customize Widget</span>
                    </div>
                </div>
                {showCustomizeWid&&isCreated&&<CustomizeWidget widget={emailApp}
                    updateLead={updateLead}
                    widgetName="email"
                />}
            </div>
        </div>
    )
}

const mapStateToProps = ({Shop}) => ({
    apps:Shop.apps
});

const mapDispatchToProps={
    createLeadWidget:createLeadWidget,
    fetchAllLeads,
    updateLead
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeadCapture);
