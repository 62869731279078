import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import FormInput from "../../components/FormInput";
import Img from "../../constants/Img";
import * as Actions from "../../store/actions";
import { isEmpty, Alert } from "../../utils";
import queryString from 'query-string'

function RegisterPage({signupUser, history, location, match}) {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [terms, acceptTerms] = useState(false);
    //console.log(location);
    

    const onClickRegister = useCallback(() => {

        if (!terms) {
            return Alert("Please check terms and conditions !", "error");
        }

        if (isEmpty([fullName, email, password])) {
            Alert('Please fill all the details !', "error");
            return;
        }

        signupUser({
            payload: {
                name: fullName,
                email: email,
                password: password,
                password_confirmation: password
            },
            history
        });
    }, [fullName, email, password, terms, history, signupUser]);


    return (
        <div id="registration" className="flex_wrapper">
            <div className="create_account">
                <div className="box1">
                    <img src={Img.regimage} alt=""/>
                    <h1>Create an account</h1>
                    <h2>
                        Just create a new account with your mail id for free, or login in
                        now, if you already have an account.
                    </h2>
                </div>
            </div>
            <div className="reg_form">
                <div className="box2">
                    <form action="" className="form" onSubmit={(e)=>e.preventDefault()}>
                        <FormInput
                            caption="Full Name"
                            id="first"
                            value={fullName}
                            onChange={setFullName}
                            required
                        />
                        <FormInput
                            value={email}
                            caption="Email ID"
                            onChange={setEmail}
                            id="last"
                            required
                        />
                        <FormInput
                            value={password}
                            onChange={setPassword}
                            caption="Create a password"
                            id="color"
                            required
                            type="password"
                        />
                    <label className="container">
                        <input type="checkbox" name="terms" checked={terms}
                               onChange={(e) => acceptTerms(e.target.checked)}/>
                        <svg id="checkbox" viewBox="0 0 100 100">
                            <polyline
                                points="15,40 35,70 85,20"
                                stroke="white"
                                fill="transparent"
                                strokeWidth="8"
                            />
                        </svg>
                        Accepting the terms and conditions
                    </label>
                    <div className="">
                        <button
                            id="registernow"
                            type="submit"
                            name="button"
                            onClick={onClickRegister}
                        >
                            Register Now
                        </button>
                        <a href="#">Cancel</a>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps = {
    signupUser: Actions.signupUser
};

export default connect(
    null,
    mapDispatchToProps
)(RegisterPage);
